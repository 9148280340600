input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.custom-doc-upload {
  display: inline-block;
  padding: 0px 10px;
  cursor: pointer;
}

.hover-pointer{
  cursor: pointer;
}
